import {
  NavigationGuard,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router'
import { getStaticUserData } from '@/api/utils/user'
import { getFirstSiblingRedirect } from '@/modules'
import { RouteError, Route } from '@/modules/base/config/routesConfig'
import {
  redirectWithError,
  isAccessibleRoute,
  getCurrentViewAuthorized,
} from '@/modules/shared/utils/routing'

const isUserAuthorized = (
  to: RouteLocationNormalized
): void | boolean | RouteRecordRaw => {
  const { userId, roleName: userRole } = getStaticUserData()

  const isOnboardingRoute = to.matched.some(
    ({ name }) => name === Route.Onboarding
  )

  if (isOnboardingRoute) {
    return true
  }

  if (
    !userId ||
    (userRole &&
      isAccessibleRoute({ authorized: getCurrentViewAuthorized(to), userRole }))
  ) {
    return true
  }

  if (userRole) {
    const fallbackRedirect = getFirstSiblingRedirect({
      route: to,
      userRole,
    })
    return fallbackRedirect ?? false
  }

  return false
}

export const userRoleAccessGuard: NavigationGuard = async (to) => {
  const isAuthorized = isUserAuthorized(to)
  if (!isAuthorized) {
    return redirectWithError(
      { ...to, name: Route.Dashboard },
      { type: RouteError.AccessDenied }
    )
  }
  return isAuthorized
}
